export enum Gender {
    MEN = "men",
    WOMEN = "women",
    UNISEX = "unisex",
    KIDS = "kids"
}

export namespace Gender {
    export const keys = [
        "men",
        "women",
        "unisex",
        "kids"
    ];

    export const values: Gender[] = [
        Gender.MEN,
        Gender.WOMEN,
        Gender.UNISEX,
        Gender.KIDS
    ];

    export function fromString(gender: string): Gender {
        switch (gender.toLowerCase()) {
            case 'men':
                return Gender.MEN;
            case 'women':
                return Gender.WOMEN;
            case 'unisex':
                return Gender.UNISEX;
            case 'kids':
                return Gender.KIDS;
            default:
                throw new Error('Invalid Gender: ' + gender);
        }
    }
}
