export enum Style {
    LIFESTYLE = "lifestyle",
    PERFORMANCE = "performance",
    ELEGANT = "elegant",
    VINTAGE = "vintage"
}

export namespace Style {
    export const keys = [
        "lifestyle",
        "performance",
        "elegant",
        "vintage"
    ];

    export const values: Style[] = [
        Style.LIFESTYLE,
        Style.PERFORMANCE,
        Style.ELEGANT,
        Style.VINTAGE
    ];

    export function fromString(style: string): Style {
        switch (style.toLowerCase()) {
            case 'lifestyle':
                return Style.LIFESTYLE;
            case 'performance':
                return Style.PERFORMANCE;
            case 'elegant':
                return Style.ELEGANT;
            case 'vintage':
                return Style.VINTAGE;
            default:
                throw new Error('Invalid Style: ' + style);
        }
    }
}
